<template lang="pug">
.page.index
  .logo
    img(src='../assets/img/logo_white.png')
  .card(:class='{ active: animation }')
    .animation_imgs
      .paper.bottom(:class='{ active: animation }')
        img(src='../assets/img/paper.png')
      .paper.top(:class='{ active: animation }')
        img(src='../assets/img/paper.png')
      .pen(:class='{ active: animation }')
        img(src='../assets/img/pen.png')

    .title “理性 VS 感性” 评测 2.0 版
    .title_en RATIONALITY TEST
    .content_tab
      .rule_title {{ showRule ? "规则说明" : "测评记录" }}
      .right_btn(v-if='isLogin', @click='changeRuleShow') {{ showRule ? "查看测评记录" : "查看规则" }}>>
    .rule(v-if='showRule') 世界上每个人都是感性和理性的混合体。如果你想知道自己的感性和理性占比，可以花 10-30 分钟的时间自测下。本测试包含各方面的知识和倾向，建议根据自己的认知进行选择。
    .rule_remark(v-if='showRule') 注：本套题不涉及价值判断，没有绝对正确，解析仅供参考。
    .list(v-if='!showRule')
      //- .li
        .date 2020-11-04 12:24
        .score 理性度 84
        .rank 超过 23% 用户
      .li(v-for='(item, i) in list', :key='i', @click='goResult(item.quizID)')
        .date {{ $formatTime(item.finishedAt, "yyyy-MM-dd HH:mm") }}
        .score 理性度 {{ item.score }} %
        .rank 超过 {{ item.proportion }}% 用户
      .nodata(v-if='!list || !list.length') 暂无测评成绩
    .btns
      .answer_btn(@click='showAnswer', v-if='isLogin && list && list.length') 查看解析
      .start_btn(@click='start', :class='{ disable: loading }') 开始答题
        img.icon(v-show='!loading', src='../assets/img/icon_start.png')
        .loader(v-show='loading')
    //- .line_btn(@click='goGroup') 加群吐槽

  AlertBox(ref='qrcode', :boxWidth='4')
    .qrcode
      .tip {{ tip }}
      .title 开始答题
      .img
        img(src='../assets/img/url_qrcode.png')

  AlertBox(ref='payBox')
    .pay_box
      .h1 支持这套题
      .h2 查看所有题目解析
      .icon
        img(src='../assets/img/icon_lq.png')
      .h3 附赠科学声音小程序
      .h3 
        span 全场 5 元优惠券
        | （每人限一张）
      .btn(@click='createOrderPay') 支付 1 元
      .tip 小提示：购买后可无限次查看解析
  AlertBox(ref='errorBox')
    .error_box
      .h1.red 支付失败
      .icon.error
        img(src='../assets/img/error.png')
      .h3 当前未能完成支付，请您重新支付
      .btn.error(@click='createOrderPay') 重新支付
  AlertBox(ref='couponBox')
    .coupon_box
      .title 恭喜您
      .info 获得科学声音小程序
      .coupon
        .l
          .t 全场优惠券
          .b 兑换码：{{ coupon }}
            button.copybtndata.copy(@click='copy', :data-clipboard-text='coupon') 复制
        .r ¥
          span 5
      .tip 说明：复制兑换码后点击“去兑换”，每人限领一次，可用于购买任意专栏
      //- .btns
      .color_btn(@click='goAnswer') 查看解析
      .color_btn.mini(@click='goCoupon') 去兑换
</template>

<script>
import { sleep, getQueryString } from '../assets/js/utils'
import AlertBox from '../components/AlertBox'
import ClipboardJS from 'clipboard'
import html2canvas from 'html2canvas'
import VConsole from 'vconsole'

let timer = null
let waitTime = 300
let lastTime = new Date().getTime()
let count = 0

export default {
  name: 'Index',
  components: {
    AlertBox
  },
  data() {
    return {
      tip: '',
      animation: false,
      loading: false,

      canCopy: false,
      isPay: false,
      coupon: '',

      showRule: true,
      list: []
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.userToken && this.$store.state.userToken.accessToken && this.$store.state.userToken.openID
    }
  },
  methods: {
    goGroup() {
      window.location.href = 'https://marketing.kexueshengyin.com/#/wechatgroup/6'
    },
    async start() {
      this.$selectAudio.play()
      if (this.list && this.list.length === 1 && !this.isPay) {
        this.$toast('你只能评测一次哦，查看解析后可多次评测。')
        return
      }
      if (!this.$store.state.isWeixin) {
        if (this.$store.state.isPC) {
          // console.log("isPC");
          this.tip = '使用微信扫码'
        } else {
          // console.log("isM");
          this.tip = '截图后打开微信扫码'
        }
        this.$refs.qrcode.show()
      } else {
        this.loading = true
        // 如果有答题记录 则不清空
        try {
          const myAnswersCurrentProgress = this.$getStore('myAnswersCurrentProgress')
          if (!myAnswersCurrentProgress || !myAnswersCurrentProgress.questions) {
            // console.log("isWeixin");
            this.$store.commit('CURRENT_QUIZZE', {})
            this.$store.commit('SET_QUESTIONS_DATA', [])
            await this.$store.dispatch('startQuizze')
          }
          setTimeout(() => {
            this.$router.push('/question')
          }, 50)
        } catch (e) {
          this.loading = false
        }
      }
    },
    showVConsole() {
      let currentTime = new Date().getTime()
      // 计算两次相连的点击时间间隔
      count = currentTime - lastTime < waitTime ? count + 1 : 1
      lastTime = currentTime
      clearTimeout(timer)
      timer = setTimeout(() => {
        clearTimeout(timer)
        if (count > 4) {
          new VConsole()
        }
      }, waitTime + 10)
    },
    copy() {
      if (!this.canCopy) return
      this.clipboard = new ClipboardJS('.copybtndata')

      this.clipboard.on('success', e => {
        e.clearSelection()
        this.$toast('兑换券已复制')
        this.clipboard && this.clipboard.destroy()
      })
      this.clipboard.on('error', () => {
        this.$toast('复制失败！')
        this.canCopy = false
        this.clipboard && this.clipboard.destroy()
      })
    },
    async getCoupons() {
      try {
        const data = await this.$api.getQuizzesOrdersCoupons()
        this.coupon = data.code
        this.isPay = true
      } catch (e) {
        // e
        this.isPay = false
      }
    },
    async createOrderPay() {
      try {
        const order = await this.$api.postQuizzeOrders(this.$store.state.currentQuizze.id || this.list[0].quizID)
        const data = await this.$api.postQuizzeOrdersPaysWechat(order.id, {
          payType: 4
        })
        // console.log(data)
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: data.appID,
            timeStamp: data.timestamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign
          },
          async res => {
            this.$refs.payBox.hide()
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              this.$loading.show()
              await sleep(2)
              try {
                await this.getCoupons()
                this.$refs.couponBox.show()
              } catch (e) {
                // -
              }
              this.$loading.hide()
            } else {
              this.$refs.errorBox.show()
            }
          }
        )
      } catch (e) {
        // e
      }
    },
    showAnswer() {
      if (this.isPay) {
        this.$refs.couponBox.show()
      } else {
        this.$refs.payBox.show()
      }
    },
    goAnswer() {
      if (this.list && this.list[0] && this.list[0].quizID) {
        this.$store.commit('CURRENT_QUIZZE', {
          id: this.list[0].quizID,
          isFinnish: true
        })
      } else {
        this.$store.commit('CURRENT_QUIZZE', {})
      }
      this.$router.push('/answer')
    },
    goCoupon() {
      window.location.href = 'https://platform.kexueshengyin.com/couponList'
    },
    changeRuleShow() {
      this.showRule = !this.showRule
    },
    async getQuizzes() {
      const data = await this.$api.getQuizzes({
        page: 1,
        size: 5
      })
      // console.log(data)
      this.list = data.list
    },
    goResult(id) {
      this.$store.commit('CURRENT_QUIZZE', {
        id: id,
        isFinnish: true
      })
      this.$router.push('/result')
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.isLogin) {
        this.getCoupons()
        this.getQuizzes()
      }
      setTimeout(() => {
        this.animation = true
      }, 200)
    })
  },
  mounted() {
    this.canCopy = ClipboardJS.isSupported()

    const ready = () => {
      this.$regWeChat()
      // const code = getQueryString('code')
      // if ((!this.$store.state.userInfo.openID || !this.$store.state.userToken.accessToken) && !code) {
      //   window.location.href.indexOf('/login') < 0 && this.$setExpireStore('fromPath', window.location.href)
      //   const url = process.env.VUE_APP_API_URL
      //   window.location.replace(`${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`)
      // }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  }
}
</script>

<style lang="stylus" scope>
.loader
  border: 0.3em solid #fff
  border-bottom-color: transparent
  border-radius: 50%
  -webkit-animation: 1s loader linear infinite
  animation: 1s loader linear infinite

@keyframes loader
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes tadaright
  from
    transform: translateX(0.1rem)

  to
    transform: translateX(0)

.index
  // overflow: hidden
  background: #0563DA
  background-size: cover
  padding-top: 0.3rem

  .logo
    width: 2.22rem
    height: 0.59rem
    margin: 0 0.3rem
    position: relative
    z-index: 5

    img
      width: 100%
      height: 100%

  .line_btn
    font-size: 0.24rem
    color: lighten(#0563DA, 30%)
    text-align: center
    padding: .1rem 0.3rem
    border-radius .5rem
    border 1px solid lighten(#0563DA, 30%)
    display: block
    width: 1.8rem
    margin: .3rem auto 0

  .card
    background: #fff
    padding: 0.82rem 0.32rem
    padding-bottom: calc(constant(safe-area-inset-bottom) / 2 + 0.6rem)
    padding-bottom: calc(env(safe-area-inset-bottom) / 2 + 0.6rem)
    position: absolute
    bottom: -100%
    margin: 0 0.16rem
    width: 7.18rem
    border-radius: 0.4rem 0.4rem 0 0
    transition: all 0.3s ease
    box-sizing: border-box

    &.active
      bottom: 0

    .animation_imgs
      position: absolute
      right: 0
      top: 0.5rem
      height: 0
      width: 0
      overflow: visible
      pointer-events: none

      .paper
        width: 4.41rem
        height: 6.08rem
        position: absolute
        right: 0
        bottom: 0
        transform-origin: center center

        &.bottom
          z-index: 1
          transition: all 0.8s ease

          &.active
            transform: rotate(-30deg)
            right: 0.4rem
            bottom: -0.2rem

        &.top
          z-index: 2
          transition: all 0.6s ease

          &.active
            transform: rotate(32deg)
            right: -1.6rem
            bottom: 2.9rem

        img
          width: 100%
          height: 100%

      .pen
        width: 0.79rem
        height: 4.17rem
        position: absolute
        z-index: 3
        right: 0
        bottom: 0
        transition: all 0.6s ease

        &.active
          transform: rotate(-28deg)
          bottom: -0.3rem
          transform-origin: 100% 100%

        img
          width: 100%
          height: 100%

    .title
      font-size: 0.46rem
      color: #333333
      line-height: 1
      position: relative
      z-index: 1
      font-weight: bold

    .title_en
      font-size: 0.66rem
      color: #E9E9E9
      line-height: 1
      position: relative
      top: -0.2rem
      z-index: 0

    .content_tab
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 0.1rem

      .rule_title
        font-size: 0.28rem
        color: #333333
        line-height: 1
        padding: 0 0.12rem
        position: relative
        display: inline-block

        &:before, &:after
          display: block
          content: ''
          height: 0.17rem
          width: 0.06rem
          background: #DC5B10
          border-radius: 0.03rem
          position: absolute
          top: 0.05rem

        &:before
          left: 0

        &:after
          right: 0

      .right_btn
        font-size: 0.28rem
        line-height: 1
        padding: 0.1rem 0 0.1rem 0.32rem

    .rule
      font-size: 0.28rem
      color: #999999
      line-height: 1.6
      margin-top: 0.28rem

      span
        color: red
        font-size: 0.32rem

    .rule_remark
      margin-top: 0.28rem
      font-size: 0.24rem
      color: #CCCCCC
      line-height: 1

    .list
      width: 100%
      margin-top: 0.3rem

      .nodata
        height: 2rem
        display: flex
        align-items: center
        justify-content: center
        font-size: 0.26rem
        color: #999

      .li
        display: flex
        align-items: center
        width: 100%
        justify-content: space-between
        line-height: 1
        padding: 0.2rem 0

        .date
          font-size: 0.24rem
          flex: 0 0 2rem
          width: 2rem

        .score
          font-size: 0.26rem
          flex: 0 0 1.8rem
          width: 1.8rem
          margin-left: 0.4rem
          margin-right: 0.1rem

        .rank
          font-size: 0.26rem
          flex: 1

    .btns
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 0.6rem

      .answer_btn
        flex: 1
        background: #FFFFFF
        border: 1px solid #979797
        border-radius: 0.5rem
        height: 1rem
        width: 3.05rem
        font-size: 0.36rem
        color: #999999
        display: flex
        flex-flow: row no-wrap
        align-items: center
        justify-content: center

      .start_btn
        flex: 1
        background: #0563DA
        border-radius: 0.5rem
        height: 1rem
        font-size: 0.36rem
        color: #FFFFFF
        display: flex
        align-items: center
        justify-content: center
        position: relative
        margin-left: 0.3rem

        &.disable
          pointer-events: none
          opacity: 0.7

        .loader
          flex: 0 0 1em
          width: 1em
          height: 1em
          position: absolute
          right: 0.4rem
          left: auto
          top: 0.3rem

        .icon
          flex: 0 0 0.37rem
          width: 0.37rem
          height: 0.31rem
          position: absolute
          right: 0.4rem
          top: 0.35rem
          animation: tadaright 1s infinite
          line-height: 0

  .qrcode
    padding: 0.4rem 0 0.5rem
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center

    .tip
      font-size: 0.28rem
      line-height: 1
      color: #999

    .title
      font-size: 0.36rem
      line-height: 1
      margin-top: 0.3rem
      color: #333

    .img
      margin-top: 0.3rem
      width: 2.5rem
      height: 2.5rem

      img
        width: 100%
        height: 100%

  .pay_box, .error_box, .coupon_box
    padding: 0.4rem 0 0.5rem
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center

    .h1
      font-size: 0.42rem
      line-height: 1
      color: #0563DA

      &.red
        color: #FE6D68

    .h2
      font-size: 0.36rem
      line-height: 1
      color: #333333
      margin-top: 0.3rem

    .h3
      font-size: 0.28rem
      line-height: 1.6
      color: #333333

      span
        color: #0563DA

    .icon
      width: 3.4rem
      height: 2.8rem
      margin: 0.4rem 0 0.3rem

      &.error
        width: 1.7rem
        height: 1.7rem
        margin: 0.6rem 0 0.6rem

      img
        width: 100%
        height: 100%

    .btn
      width: 80%
      height: 0.9rem
      text-align: center
      line-height: 0.9rem
      background: #0563DA
      border-radius: 0.45rem
      font-size: 0.36rem
      color: #FFFFFF
      margin-top: 0.3rem

      &.error
        background: #FE6D68

    .tip
      margin-top: 0.3rem
      font-size: 0.24rem
      color: #999999
      line-height: 1

  .coupon_box
    background-image: linear-gradient(180deg, #000000 0%, #10A0FF 0%, #0563DA 100%)
    border-radius: 0.28rem
    overflow: hidden

    .title
      font-size: 0.6rem
      line-height: 1
      color: #FFFFFF

    .info
      font-size: 0.36rem
      line-height: 1
      color: #FFFFFF
      margin-top: 0.3rem

    .coupon
      background: #FFFFFF
      box-shadow: 0 0.07rem 0.18rem 0 rgba(0, 0, 0, 0.19)
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 0.3rem
      border-radius: 0.26rem
      width: 5.5rem
      padding: 0.3rem 0.5rem
      box-sizing: border-box
      position: relative

      .l
        display: flex
        flex-flow: column nowrap
        align-items: flex-start

        .t
          font-size: 0.48rem
          color: #C45F00

        .b
          display: flex
          align-items: center
          margin-top: 0.1rem

          .copy
            margin-left: 0.1rem
            font-size: 0.24rem
            color: #0564DA
            display: inline-block
            padding: 0

      .r
        color: #C45F00
        font-size: 0.48rem
        padding-left: 0.2rem
        border-left: 1px solid #ddd

        span
          font-size: 1rem

    .tip
      width: 4rem
      line-height: 1.6
      margin-top: 0.3rem
      font-size: 0.24rem
      color: #FFFFFF

    .color_btn
      height: 0.9rem
      line-height: 0.9rem
      width: 80%
      font-size: 0.36rem
      color: #C45F00
      margin-top: 0.3rem
      background-image: linear-gradient(180deg, #FCE589 0%, #FBCC5D 100%)
      border-radius: 0.45rem

      &.mini
        background-image: linear-gradient(180deg, #fff 0%, #ddd 100%)
</style>
